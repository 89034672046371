import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import useSiteMetadata from "../utils/useSiteMetadata";
import Section from "./Section";
import Social from "./Social";

import footerLogo from "../images/logo-oxford.svg";

import "./Footer.scss"

const Footer = ({ location }) => {
  const { copyright, author } = useSiteMetadata();
  return (
    <>
      {location.pathname != "/connect" && location.pathname != "/connect/" && (
        <Section className="leasing">
          <div className="leasing">
            <h2 className="leasing__text text-gradient jsLeasing">
              Contact our leasing team
            </h2>
            <div>
              <Link to="/connect" className="btn" title="Connect with Square 1">
                Connect
              </Link>
            </div>
          </div>
        </Section>
      )}
      <Section className="footer">
        <div className="footer-top links">
          <div className="links__quick">
            <h2 className="title title--h3">Quick links</h2>
            <ul>
              <li>
                <Link
                  to="/district"
                  className="link"
                  activeClassName="link--active"
                  partiallyActive={true}
                >
                  <span className="link__text">SQ1 District</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/offices"
                  className="link"
                  activeClassName="link--active"
                  partiallyActive={true}
                >
                  <span className="link__text">The Offices</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/location"
                  className="link"
                  activeClassName="link--active"
                  partiallyActive={true}
                >
                  <span className="link__text">Location</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/team"
                  className="link"
                  activeClassName="link--active"
                  partiallyActive={true}
                >
                  <span className="link__text">Project Team</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/connect"
                  className="link"
                  activeClassName="link--active"
                  partiallyActive={true}
                  title="Learn More about Square 1"
                >
                  <span className="link__text">Connect</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="links__address">
            <h2 className="title title--h3">Address</h2>
            <p>
              EY Tower
              <br />
              100 Adelaide Street West
              <br />
              Suite 2100
              <br />
              Toronto, Ontario M5H 0E2
              <br />
            </p>
          </div>
          <div className="links__social">
            <div className="social__wrap">
              <h2 className="title title--h3">Social</h2>
              <Social />
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div>© {copyright}. All rights reserved</div>
          <div className="footer-logo">
            <a
              href="https://www.oxfordproperties.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={footerLogo} alt={author} />
            </a>
          </div>
          <div className="footer-bottom__terms">
            <a
              href="https://www.oxfordproperties.com/corp/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <span className="link__text">Privacy</span>
            </a>
            <a
              href="https://www.oxfordproperties.com/corp/accessibility"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <span className="link__text">Accessibility</span>
            </a>
            <a
              href="https://www.oxfordproperties.com/corp/legalnotice"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <span className="link__text">Legal notice</span>
            </a>
          </div>
        </div>
      </Section>
    </>
  );
};

Footer.propTypes = {
  location: PropTypes.object,
};

export default Footer;
