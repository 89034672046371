import React from "react";

const socialData = [
  {
    "name": "LinkedIn",
    "url": "https://www.linkedin.com/company/oxford-properties-group",
    "icon": "instagram"
  },
  {
    "name": "YouTube",
    "url": "https://www.youtube.com/user/oxfordproperties",
    "icon": "youtube"
  },
  {
    "name": "Instagram",
    "url": "https://www.instagram.com/oxfordpropgroup",
    "icon": "instagram"
  }
];

const Social = () => {
  console.log(socialData);
  const menu = socialData.map(item => item);
  return (
    <div className="social">
      <ul className="social__list">
        {menu.map((item, index) => (
          <li key={index}>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              title={item.name}
              className="link"
            >
              <span className="link__text">{item.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Social;
