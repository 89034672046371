import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import PropTypes from "prop-types";
import { DataProvider } from "../utils/DataProvider";
import { gsap } from "gsap";
import Header from "./Header";
import Footer from "./Footer";
import { Script } from 'gatsby'
import "../styles/styles.scss";

const Layout = ({ children, location }) => {
  useEffect(() => {
    // prevents flashing
    gsap.to("body", {
      duration: 0,
      css: { visibility: "visible" },
    });
  });

  return (
    <>
      <DataProvider>
        <Header location={location} />
        <Script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KFWT2WQ');`,
            }}
          ></Script>
        {/* <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          style={{
            display: "flex",
            background: "#ff7452",
            color: "black",
            padding: "0 0 10px 0",
            fontSize: "19px",
            justifyContent: "center",
          }}
          buttonStyle={{
            display: "flex",
            justifyContent: "center",
            background: "#fff",
            color: "#000",
            padding: "15px 35px",
            fontSize: "15px",
            fontWeight: "700",
            margin: "20px",
            borderRadius: "25px",
          }}
          cookieName="gatsby-gdpr-google-analytics"
        >
          You give us permission to do so by clicking on the "I Agree" button. You will find further information on cookies in our Privacy Statement
          <div className="cookieContainer">
            <style
              dangerouslySetInnerHTML={{
                __html: `
                  .cookieContainer { display: flex; flex-direction: row; max-width: 1240px; justify-content: flex-end;}

                  @media (max-width: 800px) {
                    .cookieContainer { flex-direction: column; }
                  }
                  `,
              }}
            />
            <span className="cookieTitle">
              We use cookies on our website for the purpose of site operation,
              analytics, and marketing
              <style
                dangerouslySetInnerHTML={{
                  __html: `
                    .cookieTitle { width: 35%; font-weight: bold; padding-left: 50px;}

                    @media (max-width: 1300px) {
                      .cookieTitle { width: 35%; padding-left: 30px; }
                    }

                    @media (max-width: 1000px) {
                      .cookieTitle { width: 50%; padding-left: 20px; }
                    }

                    @media (max-width: 800px) {
                      .cookieTitle { width: 100%; padding-left: 0; padding-bottom: 15px; }
                    }
                  `,
                }}
              />
            </span>
            <span className="cookieDescription">
              By clicking on the "Accept" button, you are giving us your
              permission to do so. You will find further information on cookies
              in our{" "}
              <a href="https://www.oxfordproperties.com/privacy">
                Privacy Statement
              </a>
              .
              <style
                dangerouslySetInnerHTML={{
                  __html: `
                    .cookieDescription { width: 50%; font-size: 15px; padding-right: 50px; padding-left: 50px; line-height: 1.6; font-weight: 600; }

                    .cookieDescription a {
                      text-decoration: underline;
                    }

                    @media (max-width: 1300px) {
                      .cookieDescription { width: 60%; padding-left: 40px; }
                    }

                    @media (max-width: 1000px) {
                      .cookieDescription { width: 50%; padding-left: 30px; }
                    }

                    @media (max-width: 800px) {
                      .cookieDescription { width: 100%; padding-left: 0; }
                  }
                  `,
                }}
              />
            </span>
          </div>
        </CookieConsent> */}
        {children}
        <Footer location={location}>© {new Date().getFullYear()}, </Footer>
        {/* <CookieBanner /> */}
      </DataProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
