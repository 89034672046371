import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
let tl = gsap.timeline();

export const fadeIn = (el) => gsap.from(el, {
  autoAlpha: 1,
  scale: 1,
});

export const fadeOut = (el) => gsap.to(el, {
  autoAlpha: 0,
  scale: 1.5,
});

//Open menu
export const menuShow = (elem1, elem2) => {
  gsap.from([elem1, elem2], {
    duration: 0.7,
    height: 0,
    transformOrigin: "right top",
    skewY: 2,
    ease: "power4.inOut",
    stagger: {
      amount: 0.2,
    },
  })
}

//Close menu
export const menuHide = (elem1, elem2) => {
  gsap.to([elem1, elem2], {
    duration: 0.8,
    height: 0,
    ease: "power4.inOut",
    stagger: {
      amount: 0.07,
    },
  })
}

// Hover on the link
export const hoverLink = e => {
  gsap.to(e.target, {
    duration: 0.4,
    y: 3,
    skewX: 4,
    ease: "power2.inOut"
  });
};

// Hover away from the link
export const hoverExit = e => {
  gsap.to(e.target, {
    duration: 0.4,
    y: -3,
    skewX: 0,
    ease: "power2.inOut"
  });
};

//Stagger links
export const staggerLinks = (elem1, elem2, elem3) => {
  gsap.from([elem1, elem2, elem3], {
    duration: 0.8,
    y: 100,
    delay: 0.1,
    ease: "power4.inOut",
    stagger: {
      amount: 0.3,
    },
  })
}

export const heroText = (el) => {
  gsap.from([el], {
    duration: 1.8,
    yPercent: 100,
    ease: "power4",
    stagger: 0.1,
    delay: 1,
  })
}

export const navAnimation = () => {
  gsap.from('.jsLocalNav li', {
    duration: 1.6,
    opacity: 0,
    y: 10,
    ease: "power4.inOut",
    stagger: 0.1
  })
}

export const revealText = (el, delay = 0) => {
  gsap.from([el], {
    duration: 1.8,
    opacity: 0,
    yPercent: 100,
    ease: "power4",
    stagger: 0.1,
    delay: delay,
    scrollTrigger: {
      trigger: el,
      start: 'top 100%',
      toggleActions: 'play reverse play reverse',
    }
  })
}

export const moveLines = (
  position = '50vw',
  source = '.jsMoveLinesImageText',
  target = '.jsSectionImageText', ) => {
  gsap.to(source, {
    x: position,
    duration: 1,
    ease: "power3.easeIn",
    scrollTrigger: {
      trigger: target,
      start: 'top 100%',
      end: '50%',
      scrub: 1,
    }
  })
}

export const bigTitle = (el) => {
  gsap.set(`${el} .ui-block__text`, {autoAlpha: 0, yPercent: 50, skewY: 7});
  ScrollTrigger.batch(`${el} .ui-block`, {
    onEnter: batch => {
      batch.forEach((section, i) => {
        gsap.to(section.querySelectorAll(`${el} .ui-block__text`), {
          autoAlpha: 1,
          yPercent: 0,
          duration: 0.8,
          skewY: 0,
          ease: "power4.easeOut",
          stagger: 0.2,
          delay: i * 0.1
        });
      });
    },
    onEnterBack: batch => {
      batch.forEach((section, i) => {
        gsap.from(section.querySelectorAll(`${el} .ui-block__text`), {
          autoAlpha: 0,
          yPercent: 100,
          duration: 0.8,
          ease: "power4.easeOut",
          stagger: 0.2,
          delay: i * 0.1
        });
      });
    },
    start: "top 95%",
  })
}

export const coverImage = () => {
  gsap.to('.jsCoverImage', {
    duration: 2,
    top: '-10%',
    scrollTrigger: {
      trigger: '.jsSectionImageText',
      start: '-50%',
      end: '70%',
      scrub: true,
    }
  })
}

export const imageReveal = (el = '', start = 'top 100%', delay = 0) => {
  gsap.set(`${el} + .zoom-out__rev`, {width: '100%'});
  gsap.from(el, {
    duration: 1,
    scale: 1.4,
    ease: "power2.easeInOut",
    delay: delay,
    scrollTrigger: {
      toggleClass: 'active',
      trigger: el,
      start: start,
      toggleActions: 'play reverse play reverse',
      //onEnterBack: () => console.log('enter back'),
      // onEnter - scrolling down, start meets scroller-start
      //onLeave - scrolling down, end meets scroller-end
      // onEnterBack - scrolling up, end meets scroller-end
      // onLeaveBack - scrolling up, start meets scroller-start
      //onUpdate: self => self.isActive ? document.querySelector(el).classList.add("active") : ''
    }
  })
}

export const barReveal = (el) => {
  gsap.from(el, {
    ease: "power3.easeOut",
    scrollTrigger: {
      trigger: el,
      start: 'top 80%',
      toggleClass: 'active'
    }
  })
}

export const bLabelReveal = (el) => {
  gsap.from(el, {
    x: -50,
    autoAlpha: 0,
    duration: 1,
    ease: "power3.easeOut",
    scrollTrigger: {
      trigger: el,
      start: 'top 80%',
    }
  })
}

export const coverReveal = (direction = '-100%') => {
  gsap.set('.jsCoverRevealS1', {width: '100%', x: 0});
  gsap.to('.jsCoverRevealS1', {
    duration: 1.5,
    x: direction,
    transformOrigin: 'right center',
    ease: "power4.inOut",
    scrollTrigger: {
      trigger: '.jsCoverRevealS1',
      start: 'top 100%',
      toggleActions: 'play reverse play reverse',
    }
  })
}


