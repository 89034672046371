import React from "react";
import { Link } from "gatsby";

const Logo = () => {
  return (
    <Link to="/" className="header__link jsLogo">
      <strong>The Offices</strong>
      <span>by Square One District</span>
    </Link>
  );
};

export default Logo;
