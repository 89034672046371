import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { gsap } from "gsap";
import { graphql, useStaticQuery } from "gatsby";
import lines from "../images/lines-thin.svg";
import Logo from "./Logo";
import {
  menuShow,
  menuHide,
} from "../animations/Animate";

import "./Navigation.scss";

const subNavQuery = graphql`
  query sectionsOffices {
    officesSections: officesPageJson {
      sectionsQuery: sections {
        section1 {
          anchor
          name
        }
        section3 {
          anchor
          name
        }
        section4 {
          anchor
          name
          brochure
        }
        section2 {
          anchor
          name
        }
        section5 {
          anchor
          name
        }
      }
    }

    locationSections: locationPageJson {
      sectionsQuery: sections {
        section1 {
          anchor
          name
        }
        section2 {
          anchor
          name
        }
        section3 {
          anchor
          name
        }
      }
    }
  }
`;

const Navigation = ({ state, onclick }) => {
  const subMenu = useStaticQuery(subNavQuery);
  const navOffices = Object.values(subMenu.officesSections.sectionsQuery);
  const navLocation = Object.values(subMenu.locationSections.sectionsQuery);
  // refs for our DOM elements
  let menuWrapper = useRef(null);
  let show1 = useRef(null);
  let show2 = useRef(null);
  let menuHead = useRef(null);
  let closeButton = useRef(null);
  let downloadBrochure = useRef(null);

  const menuAnimation = () => {
    gsap.to(menuWrapper, { duration: 0, css: { display: "block" } });
    //Allow menu to have height of 100%
    gsap.to([show1, show2], {
      duration: 0,
      opacity: 1,
      height: "100%",
    });
    menuShow(show1, show2);
    gsap.from(
      ".lines",
      {
        duration: 2,
        opacity: 0,
        x: -100,
        delay: 0.5,
        ease: "power4.inOut",
      },
      "-=1"
    );
    gsap.from(menuHead, {
      duration: 1,
      opacity: 0,
      y: 30,
      delay: 1,
      ease: "power4.inOut",
      stagger: {
        amount: 0.2,
      },
    });
    gsap.from(".main-nav__links li", {
      duration: 1.2,
      opacity: 0,
      y: 20,
      delay: 1.2,
      ease: "power4.inOut",
      stagger: {
        amount: 0.2,
      },
      onComplete: () =>
        document.querySelector(".main-menu").classList.add("active"),
      onLeave: () =>
        document.querySelector(".main-menu").classList.remove("active"),
    });
    gsap.from(downloadBrochure, {
      opacity: 0,
      duration: 1.2,
      delay: 1.5,
      ease: "power4.inOut",
    });
    gsap.from(closeButton, {
      opacity: 0,
      //scale: 0,
      duration: 2,
      delay: 3,
      ease: "power4.inOut",
    });
  };

  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.

      menuHide(show2, show1);
      // Set menu to display none
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      menuAnimation();
    }
  }, [state]);

  return (
    <div ref={el => (menuWrapper = el)} className="menu-wrapper">
      <div ref={el => (show1 = el)} className="menu-reveal"></div>
      <div ref={el => (show2 = el)} className="menu-reveal-layer">
        <div className="menu__root">
          <img src={lines} className="lines hidden-mobile" alt="lines" />
          <div className="main-menu">
            <div className="main-menu__head" ref={el => (menuHead = el)}>
              <Logo />
              <span className="main-menu__label hidden-mobile">Navigation</span>
              <div className="main-menu__close">
                <button
                  ref={el => (closeButton = el)}
                  aria-label="Close Menu"
                  className="close-menu"
                  onClick={() => onclick()}
                >
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
            <div className="main-nav">
              <div className="main-nav__links">
                <ul>
                  <li>
                    <Link
                      activeClassName="main-nav--active"
                      partiallyActive={true}
                      to="/district"
                    >
                      SQ1 District
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClassName="main-nav--active"
                      to="/offices"
                      partiallyActive={true}
                    >
                      The Offices
                    </Link>
                    <ul>
                      {navOffices.map((item, index) => (
                        <li key={index}>
                          <Link to={`/offices#${item.anchor}`} className="link">
                            <span className="link__text">{item.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link
                      activeClassName="main-nav--active"
                      to="/location"
                      partiallyActive={true}
                    >
                      Location
                    </Link>
                    <ul>
                      {navLocation.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={`/location#${item.anchor}`}
                            className="link"
                          >
                            <span className="link__text">{item.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <Link
                      activeClassName="main-nav--active"
                      to="/team"
                      partiallyActive={true}
                    >
                      Project Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClassName="main-nav--active"
                      to="/connect"
                      partiallyActive={true}
                      title="Learn More about Square 1"
                    >
                      Connect
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="main-menu-button"
                ref={el => (downloadBrochure = el)}
              >
                <a
                  href={navOffices[2].brochure}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                >
                  Download Brochure
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Navigation.propTypes = {
  state: PropTypes.object,
  onclick: PropTypes.func
};

export default Navigation;
