import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useData } from '../utils/DataProvider'
import Navigation from "./Navigation"
import Logo from "./Logo"

import './Header.scss';

const Header = ({location}) => {
  const [state, setState] = useData()

  const locRef = useRef({
    location: null
  })

  const [menuState, setMenuState] = useState({
    initial: false,
    clicked: null,
    menuName: "Menu"
  });

  const [disabled, setDisabled] = useState(false);

  const openMenu = (action) => {
    setState({ ...state, isMenuOpen: action});
  }

  //toggle menu
  const handleMenu = () => {
    disableMenu();
    if (menuState.initial === false) {
      setMenuState({
        initial: null,
        clicked: true,
      });
      openMenu(!state.isMenuOpen)
    } else if (menuState.clicked === true) {
      setMenuState({
        clicked: !menuState.clicked
      });
      openMenu(!state.isMenuOpen)
    } else if (menuState.clicked === false) {
      setMenuState({
        clicked: !menuState.clicked
      });
      openMenu(!state.isMenuOpen)
    }
  };

  // check if out button is disabled
  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  const handleClose = () => {
    setMenuState({
      clicked: !menuState.clicked
    });
    openMenu(!state.isMenuOpen)
  }

  useEffect(() => {
    // set the location on initial load
    if (!locRef.current.location) {
      locRef.current.location = location
    }
    // make sure overlay is closed on route change
    else if (locRef.current.location !== location) {
      setMenuState({ clicked: false});
      locRef.current.location = location
    }
  }, [location])

  const headerInlineCSS = {
    '--bgr-header': `var(--color-${state.color})`

  };
  return (
    <>
      <div className="header-root">
        <div
          className="header-root__wrap"
          data-active={state.isFixed}
          style={state.isFixed ? headerInlineCSS : null} >
          <div className="header-root__inner">
            <div className="header" ref={locRef}>
              <div className="header__logo" >
                <Logo />
              </div>
              <div className="header__nav nav-toggle">
                <button
                  disabled={disabled}
                  onClick={handleMenu}
                  className="header__menu jsHeaderMenu"
                  aria-label="Open Menu"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
          </div>
          </div>
        </div>
      </div>
      <Navigation state={menuState} onclick={() => { handleClose() }} />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
